<script setup>
  import { ref } from 'vue';
  import { Link } from '@inertiajs/vue3';
  import Logo from '@/Components/Logo.vue';
  import NavbarToggleButton from './NavbarToggleButton.vue';
  import SearchBar from '@/Components/SearchBar.vue';

  const showMobileNav = ref(false);
  const toggleNavbar = () => {
    showMobileNav.value = !showMobileNav.value;
  };
</script>

<template>
  <div class="flex flex-row items-center w-full h-20 pt-20 pb-6 text-white bg-white">
    <div class="w-1/3">
      <div class="w-2/3 mx-auto">
        <a href="/">
          <Logo />
        </a>
      </div>
    </div>

    <div class="w-1/3">
      <div class="flex w-full">
        <!-- <div
          class="flex items-center w-full px-4 pl-0 bg-white border-4 border-black rounded-full lg:pl-6">
          <input
            type="text"
            id="search"
            name="search"
            placeholder="Search"
            class="flex-grow w-full text-sm font-bold text-black uppercase bg-transparent border-none focus:outline-none lg:text-2xl" />
          <button class="px-1 lg:px-4">
            <i class="text-black fa fa-search"></i>
          </button>
          <select
            name="filter"
            id="filter"
            class="-ml-2 text-sm font-bold text-gray-500 uppercase bg-transparent border-none focus:outline-none lg:ml-2 lg:text-2xl">
            <option value="talent">Talent</option>
            <option value="gigs">Gigs</option>
          </select>
        </div> -->

        <SearchBar />
      </div>
    </div>

    <div class="flex flex-row w-1/3">
      <div class="flex-row hidden w-full ml-6 text-black md:flex md:flex-row">
        <ul class="flex flex-row gap-6 text-base font-bold uppercase lg:text-2xl">
          <li>Features</li>
          <li>
            <a href="/login" class="text-black">Login</a>
          </li>
        </ul>
        <div class="flex justify-center w-full">
          <div
            class="rounded-full bg-gradient-to-r from-[#089BAC] to-[#77D65C] p-1 font-bold uppercase text-white">
            <a href="/register" class="text-black">Sign Up</a>
          </div>
        </div>
      </div>
      <div class="justify-end mt-2 ml-20 md:hidden">
        <NavbarToggleButton @toggle-navbar="toggleNavbar" />
      </div>
    </div>

    <div
      v-show="showMobileNav"
      id="navbarSupportedContent"
      class="absolute right-0 z-50 w-full mt-10 text-xl font-bold text-center bg-gray-500 h-50 top-20 md:hidden">
      <div class="flex flex-col gap-2 py-3">
        <a href="/login">Login</a>
        <a href="/register">Sign Up</a>
      </div>
    </div>
  </div>
</template>
